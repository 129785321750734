import React from "react"

const PartnersHeader = ({ header }) => {
  return (
    <div className="partners-header">
      <h1>{header}</h1>
    </div>
  )
}

export default PartnersHeader
