import React from "react"

import SEO from "../components/seo"
import { useLogos } from "../hooks/useLogos.component"
import PageHeadline from "../components/pageHeadline/PageHeadline.component"
import PartnersHeader from "../components/partnersPage/PartnersHeader.component"
import PartnersLogos from "../components/partnersLogos/PartnersLogos.component"
import SectionHeader from "../components/sectionHeader/SectionHeader.component"
import ProjectsContainer from "../components/projectsContainer/ProjectsContainer.component"

const Partners = () => {
  const {
    consultantsAndFinancialPartners,
    brands,
    artistsAndDesigners,
  } = useLogos()

  const artists = [
    "Salvador Dali",
    "Les Lalanne",
    "Cai Gui-Qiang",
    "Jean Gunand",
    "Anish Kapoor",
    "Jenny Holzer",
    "James Turrell",
    "Dominic Harris",
    "Tatsuo Miyajima",
    "Mary Weatherford",
    "Robert Irwin",
    "Teamlab",
    "Daniel Rozin",
    "Daniel Canogar",
    "Marina Zurkow",
    "Quayola",
    "Rafael Lozano-Hemmer",
    "UVA",
    "Manfred Mohr",
  ]

  return (
    <>
      <SEO title="Partners" />
      <main className="partners">
        <PageHeadline headline1="Our" headline2="Partners" color="#E7D7C4" />
        <PartnersHeader header="Consultants & Financial Partners" />
        <PartnersLogos logos={consultantsAndFinancialPartners} />
        <PartnersHeader header="Brands" />
        <PartnersLogos logos={brands} />
        <PartnersHeader header="Artists & Designers" />
        <PartnersLogos logos={artistsAndDesigners} />
        <div className="artist-names">
          <ul>
            {artists.map((artist) => (
              <li key={artist}>{artist}</li>
            ))}
          </ul>
        </div>
      </main>
      <SectionHeader headline="Featured projects" />
      <ProjectsContainer max={2} />
    </>
  )
}

export default Partners
